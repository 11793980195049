/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import "./recent.css";

const RecentWork = () => {
  return (
    <div className="recentWork">
      <p>Optimisation of CorticalFlow for Cortical Surface Reconstruction</p>
      <h4>
        Honours Thesis &nbsp; • &nbsp; CSIRO AERHC &nbsp; • &nbsp;
        <a href="https://lebrat.github.io/CorticalFlow/">website</a> • &nbsp;
        <a href="https://arxiv.org/abs/2206.06598">paper</a>
      </h4>
      <p>Voxel2Mesh for Cortical Surface Reconstruction</p>
      <h4>
        CSIRO Vacation Scholarship &nbsp; • &nbsp;{" "}
        <a href="/voxel2mesh-presentation.pdf">slides</a>
      </h4>
      <p>UQ Planner</p>
      <h4>
        Timetable planning app for UQ students &nbsp; • &nbsp;
        <a href="https://uqplanner.app">uqplanner.app</a>
      </h4>
    </div>
  );
};

export default RecentWork;
